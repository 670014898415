/* Hide scrollbar for Chrome, Safari and Opera */
#element::-webkit-scrollbar {
display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
#element {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.backMain{
    background: linear-gradient(-45deg,#FFFBE9,#E3CAA5,#CEAB93,#AD8B73 );
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 100%;
    min-height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}